import React, { useEffect, useState, useRef } from 'react';
import Switch from "react-switch";

import MouseTracker from './partials/Coordinates'; //keep
import MapLink from './partials/MapLink';
import Cat from './Cat';
import { datajson } from './Data';

const Home = () => {
  const [startScreen, setStartScreen] = useState(true)
  const [flow, setFlow] = useState(false)
  const [outVideo, setOutVideo] = useState(false)
  const [outBG, setOutBG] = useState(false)

  const [mapPosition, setMapPosition] = useState(null) // <-- null
  const playNext = useRef(false)
  const playOpening = useRef(true) // <-- true

  /**
   * 
   */
  const backToStart = () => {
    setStartScreen(false)
    setMapPosition(null)
  }

  /**
   * 
   */
  const backNoStart = () => {
    if (datajson[mapPosition].out_vid) {
      setOutBG(datajson[mapPosition].bg)
      setMapPosition(null)
      setStartScreen(false)
      setOutVideo(datajson[mapPosition].out_vid)
    } else {
      playNext.current = false
      setStartScreen(true)
      setMapPosition(null)
    }
  }

  /**
   * 
   */
  const handleOutVideoEnd = () => {
    setOutVideo(false)
    playNext.current = false
    setStartScreen(true)
  }

  /**
   * 
   */
  const nextProduct = () => {
    if (mapPosition === 'flaire') {
      backNoStart()
    } else {
      const next = datajson[mapPosition].next;
      changeMapPosition(next)
    }
  }

  /**
   * 
   */
  const changeMapPosition = (pos) => {
    if (!mapPosition) {
      playOpening.current = true
    } else {
      playOpening.current = false

      if (datajson[pos].next_vid) {
        playNext.current = true
      } else {
        playNext.current = false
      }
    }

    setMapPosition(pos)
  }

  return (
    <>
      {mapPosition ? (
        <Cat data={datajson[mapPosition]} goNext={nextProduct} goMap={backNoStart} playNext={playNext} playOpening={playOpening} />
      ) : (
        <>
          {outVideo ? (
            <>
              <video 
                controls={false} 
                preload="none" 
                muted 
                className='absolute w-full h-full top-0 right-0 left-0 bottom-0 z-20'
                playsInline
                autoPlay
                onEnded={handleOutVideoEnd}
                src={outVideo}
              >
              </video>
              <img src={outBG} className='w-full z-10' />
            </>
          ) : (
            <header className={`p-8 bg-white bg-opacity-70 absolute top-0 left-0 transition-all flex items-center z-50 ${startScreen ? 'rounded-br-xl' : 'right-0 w-full'}`}>
              <div className={`w-full ${startScreen ? '' : 'w-1/2'}`}>
                <img src="assets/logo.svg" className='w-[250px]' />
              </div>
              {!startScreen && (
                <div className='w-1/2 text-right'>
                  <a className='button text-3xl inline-block pt-1 pb-1 px-5' onClick={() => {setStartScreen(true)}}>Begin Here</a>
                </div>
              )}
            </header>
          )}
            
          {startScreen && (
            <>
              <div className='absolute w-full h-full z-30 animate-quickFade'>
                {Object.keys(datajson).map(key => (
                  datajson[key].mapx > 0 && (
                    <MapLink 
                      key={key}
                      onClick={() => {changeMapPosition(key)}}
                      title={datajson[key].title}
                      buttonX={datajson[key].mapx}           // mapx
                      buttonY={datajson[key].mapy}           // mapy
                      line2XEnd={datajson[key].linex}    // linex
                      line2YEnd={datajson[key].liney}    // liney
                      line1Offset={datajson[key].lineoff}    // lineoff
                      circleOX={datajson[key].circleOX}
                      circleOY={datajson[key].circleOY}
                      //c={datajson[key].mapclass} 
                      startLabel={(datajson[key].startL ? datajson[key].startL : false)}
                    />
                  )
                  ))}
              </div>

              <div className='absolute right-5 top-5 text-white animate-quickFade flex justify-end items-end z-50 flex-col'>
                <div className='flex items-end justify-center'>
                  <a className='button bg-darkblue bg-opacity-50 hover:bg-opacity-100 text-2xl inline-block mb-5 pt-1 pb-1 px-5 mr-4' href='assets/catalog.pdf' target='_blank' rel="noopener noreferrer">Full Product Catalog</a>
                  <a className='button bg-darkblue bg-opacity-50 hover:bg-opacity-100 text-2xl inline-block mb-5 pt-1 pb-1 px-5' onClick={() => {setStartScreen(false)}}>Back</a>
                </div>
                
                <div>
                  <Switch
                    checked={flow}
                    onChange={() => {setFlow(flow => !flow)}}
                    onColor="#3E69B8"
                    onHandleColor="#ffffff"
                    handleDiameter={30}
                    uncheckedIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          fontSize: 15,
                          color: "white",
                          paddingRight: 2
                        }}
                      >
                        Flow
                      </div>
                    }
                    checkedIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          fontSize: 15,
                          color: "white",
                          paddingRight: 2
                        }}
                      >
                        Off
                      </div>
                    }
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={50}
                    width={100}
                    className="react-switch"
                    id="material-switch"
                  />
                </div>
              </div>
            </>
          )}

          {flow && (
            <video 
              controls={false} 
              preload="none" 
              muted 
              className='absolute w-full h-full top-0 right-0 left-0 bottom-0 z-10'
              playsInline
              autoPlay
              loop
              src='assets/flows/1_Site_Flowlines_Update_V2_1.webm'
            >
            </video>
          )}
          
          
          {/* Uncomment below if you need the mouse tracker 
          <MouseTracker />  */}
        </>
      )}
      
      <img src="assets/stills/0_MainSiteZoomout_Still___compressed.jpg" alt='Home Background' className='w-full z-0' />
    </>
  )
}

export default Home