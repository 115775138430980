const DettronicsX3301 = () => {
  return (
    <div>
      <p>The X3301 delivers fast fire detection performance while limiting nuisance and false alarms. Versatile, robust, reliable, developed and built with the needs of hazardous and critical industrial process operators in mind.</p>
      <h1>Features:</h1>
      <ul class="ul1">
 	<li class="li1">Multiple sensitivity modes</li>
	 <li class="li1"> Optical Integrity (oi®) performs automatic fail-safe checks every 60 seconds</li>
	 <li class="li1"> Heated optics for adverse environments and weather</li>
	 <li class="li1"> Data logger with event time and date stamping (1500 events)</li>
	 <li class="li1"> Longest FM Approved detection range</li>
</ul>
<h1>Technical:</h1>
      <ul class="ul1">
 	<li class="li1">Multiple communication protocols, including 0-20 mA, relay, Modbus, HART, FDT®/DTM and Eagle Quantum Premier® (EQP) safety system</li>
<li class="li1"> Diagnostic LED for local alarm and simple fault identification</li>
<li class="li1"> Large capacity event log storage (1,500 events)</li>
<li class="li1"> HART capable</li>
	</ul>
      <h1>Appllications:</h1>
      <ul class="ul1">
 	<li class="li1">Tanks</li>
	 <li class="li1">Compressors</li>
	 <li class="li1">Motors & Drives</li>
	 <li class="li1">Pumps</li>
	 <li class="li1">Anywhere there is a potential for flame</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/fire-safety/fire-gas-detection/flame-detection/det-tronics-x3301/">Det-Tronics X3301 | Flame Detectors Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default DettronicsX3301
