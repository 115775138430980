const PenberthyGauges = () => {
  return (
    <div>
      <p>Penberthy Models RL and TL Low-Pressure Flat Glass Gages are a reliable and cost-effective solution for monitoring liquid levels. Their rugged design, accurate readings, and easy-to-read flat glass make them an excellent choice for ensuring safe and efficient operations in low-pressure applications.</p>
      <h1>Features:</h1>
      <ul class="ul1">
    <li class="li1">Reliable performance: ensuring accurate measurement over time</li>
    <li class="li1">Easy to understand level reference</li>
    <li class="li1">Transparent style for visual inspection</li>
    <li class="li1">Non-intrusive operation</li>
    <li class="li1">No electrical power required</li>
    <li class="li1">Suitable for full vacuum applications</li>
    <li class="li1">Near-unlimited length of measure</li>
    <li class="li1">Optional offshore coating 2600 protection</li>
    <li class="li1">NACE wetted materials available</li>
    <li class="li1">Used for verification of other level instrument technology</li>
    <li class="li1">Standard flat gasket seat</li>
    <li class="li1">Optional shields for prolonging glass life</li>
</ul>

      <h1>Appllications:</h1>
      <ul class="ul1">
 	<li class="li1">Monitoring fluid levels</li>
 	<li class="li1">Steam condensate measurement</li>
 	<li class="li1">Safety assurance</li>
 	<li class="li1">Process control optimization</li>
 	<li class="li1">Environmental compliance</li>
 	<li class="li1">Maintenance planning</li>
 	<li class="li1">Energy efficiency enhancement</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/valves-actuation/steam-traps-valves-and-level-gauges/level-instrumentation/penberthy-models-rl-and-tl-low-pressure-flat-glass-gages/">Penberthy Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default PenberthyGauges
